<template>
 <Head>
      <title>{{title}}</title>
      <meta name="description" :content="description" />
      <meta name="keywords" :content="keywords" />
    </Head>


  <div class="home-container">

    <SplashContainer :tiled="true" :title="splashTitle" :subtitle="splashSubtitle" :splashImageUrl="splashImage">
      <div class="mt-2 header-separator"></div>
      <router-link to="/order" class="mt-4 order-now-btn">
      <img class="order-now-btn-img" src="../assets/img/sticker-btn.webp">

      <div class="d-flex">
        <p class="m-0">Order Now</p>
        <img alt="arrow right" class="ms-2" style="height:20px;" src="../assets/img/arrow-right.webp">
      </div>
     
      </router-link>



    </SplashContainer>

    <p class="splash-note">"Fresh food comes to<br>those who wait"</p>


    <div class="social-media-container">
      <a href="https://www.facebook.com/theislandhouseuk" target="_blank"><i class="bi bi-facebook"></i></a>
      <a href="https://www.tiktok.com/@the.island.house?_t=8pzDAP0tHe5&_r=1" target="_blank"><i class="bi bi-tiktok"></i></a>
      <a href="https://www.instagram.com/theislandhousetakeaway/?hl=en" target="_blank"><i class="bi bi-instagram"></i></a>
    </div>


    <!-- Our Story -->
    <div class="d-flex flex-column mt-4 mb-5 align-items-center mx-auto" style="max-width:800px;">
      <h4 class="text-primary">Discover</h4>
      <h2>Our Story</h2>
      <div class="mt-2 header-separator"></div>

      <div class="d-flex mx-4 mx-md-0 align-items-center mt-4 our-story">

        <img alt="our story" class="our-story-img mb-3 mb-md-0 col me-0 me-md-4" src="../assets/img/our-story.webp">

        <div class="col">
          <div class="d-flex our-story-title-container">
            <div class="d-none d-md-block me-2 vertical-separator"></div>
            <h3 class="our-story-title">Welcome to The Island House</h3>
          </div>
          <p class="our-story-content">With over 40 years experience, we pride ourselves on using the
            best ingredients and quality products only, ensuring 100% cod in our fish.<br>The Island House is renowned
            throughout the Black Country for its divine style and presentation of traditional Fish and chips, this is
            achieved by paying special attention to every fine detail and only using the very finest ingredients.</p>
        </div>

      </div>
    </div>


    <!-- Online Ordering -->
    <div class="online-ordering">
  <RuggedSeparator :isTop="true"/>



  <div class="online-ordering-inner-container">
    <h4 class="text-foreground-dark">Check out</h4>
    <h2>Online Ordering</h2>
    <div class="mt-2 header-separator"></div>


    <div class="d-flex mt-4 online-ordering-inner-container-content" style="width:100%;">
      <img alt="app example" class="col-4 online-ordering-img"  src="../assets/img/app-example.webp">


      <div class="col-md-8 col-12">
        <p class="app-title title-lg">Start your order!</p>
        <router-link to="order" class="btn btn-white">
          <span>Order Online</span>
          <img alt="arrow right" class="ms-2" style="height:20px;" src="../assets/img/arrow-right-dark.webp">
        </router-link>
        <p class="my-4">Or download our bespoke app for an enhanced ordering experience!</p>
        <div class="app-link-container align-self-start d-flex align-items-center">
          <a target="_blank" href="https://apps.apple.com/gb/app/the-island-house/id6450675410" class="btn btn-secondary me-2">
            <img alt="app store" class="me-2" style="height:20px;" src="../assets/img/app-store.webp">
            <span>Apple Store</span>
          </a>
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.orderopia.theislandhouse" class="btn btn-secondary">
            <img alt="google play" class="me-2" style="height:20px;" src="../assets/img/google-play.webp">
            <span>Play Store</span>
          </a>

        </div>
      </div>


    </div>
  </div>


  
  <RuggedSeparator :isTop="false"/>


</div>


    <!-- Menu Item Images-->
    <div class="menu-item-images">
      <img alt="menu item" src="../assets/img/menu-item-6.webp">
      <img alt="menu item" src="../assets/img/menu-item-2.webp">
      <img alt="menu item" src="../assets/img/menu-item-3.webp">
      <img alt="menu item" src="../assets/img/menu-item-1.webp">
      <img alt="menu item" src="../assets/img/menu-item-4.webp">
      <img alt="menu item" src="../assets/img/menu-item-5.webp">
      <img alt="menu item" src="../assets/img/menu-item-7.webp">
      <img alt="menu item" src="../assets/img/menu-item-8.webp">

    </div>






  </div>
</template>

<script>
import RuggedSeparator from '../components/RuggedSeparator.vue'
import SplashContainer from '../components/SplashContainer.vue'

import splash from "../assets/img/splash.webp";
import { Head } from '@vueuse/head';

export default {
  components: {
    RuggedSeparator,
    SplashContainer,
    Head
  },
  data() {
    return {
      title: "The Island House | Best Fish & Chips",
      description: "Fresh food from The Island House. Order now. The food you really want is just one click away. Order fish and chips online.",
      keywords: "islandhousefishbar,the island house, islandhouse,island house, island house fish and chips, fish, chips, chippy",
      splashImage: splash,
      splashSubtitle: "THE ISLAND<br>HOUSE",
      splashTitle: ""

    }
  }

}

</script>

<style scoped>

.order-now-btn {
position: relative;
height:45px;
}

.order-now-btn > div {
  height:100%;
  position:absolute;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.order-now-btn-img {
  height:50px;
}

.order-now-btn > div > p {
  font-size:20px;
  white-space: nowrap;
}

.splash-note {
  font-family: "Indie Flower", cursive;
  font-weight: 400;
  font-style: normal;
  position:absolute; 
  bottom: 120px; 
  width:300px; 
  transform:translateX(-50%); 
  left:50%; 
  text-align: center; 
  font-size: 28px;
}



.social-media-container {
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  left:25px;
  font-size:30px;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Adds 20px space between the children */

}


.vertical-separator {
  width: 1px;
  height: 100%;
  background-color: white;
}


.our-story-title-container {
  height: 60px;
}



.our-story-title {
  max-width: 240px;
}

.online-ordering {
  position: relative;
  width: 100%;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  margin: 0 auto 0 auto;
  align-items: center;
  padding: 2rem 0 5rem 0;
}

.online-ordering-inner-container {
  max-width: 800px;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.online-ordering-inner-container-content {
  flex-direction: row-reverse;
}

.online-ordering-img {
  height: 300px;
  object-fit: contain;
  margin: auto;
}

.menu-item-images {
  justify-content: center;
  overflow-y: hidden;
  margin: 0 auto;
  width: 100%;
  height: 200px;
  overflow-x: hidden;
  display: flex;
}

.menu-item-images img {
  height: 200px;
  display: block;
  width: 200px;
  object-fit: cover;
}

.app-title {
    max-width:350px;
    padding:0 10px;
  }


@media (max-width:768px) {

  .app-title {
    max-width:none;
    width:100%;
  }

  .order-now-btn {
height:35px;
}


.order-now-btn-img {
  height:40px;
}

  .app-link-container {
  display:flex;
  align-items: center;
  justify-content: center;
}
  .our-story {
    flex-direction: column;
  }



  .our-story-img {
    width: 100%;
  }

  .our-story-title-container {
    height: auto;
  }

  .our-story-title {
    max-width: 1000px;
  }


  .online-ordering-inner-container-content {
    flex-direction: column;
    text-align: center;
    align-items: center;

  }

  .online-ordering-inner-container-content .btn-white {
    margin: 0 auto;
  }

  .menu-item-images {
    flex-wrap: wrap;
    height: 400px;
  }





}

@media (max-width:768px) {

.order-now-btn {
height:30px;
}


.order-now-btn-img {
height:35px;
}
}
</style>
