/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';
import TiptonImageUrl from "../img/tipton-outside.webp";
import StaffordRdImageUrl from "../img/staffordrd-outside.webp";
import EdgbastonImageUrl from "../img/edgbaston-outside.webp";
import NewbridgeImageUrl from "../img/newbridge-outside.webp";
import DudleyImageUrl from "../img/dudley-outside.webp";
import EveshamImageUrl from "../img/evesham-outside.webp";
import DudleyPortImageUrl from "../img/dudleyport-outside.webp";
import ShardEndImageUrl from "../img/outside-shard-end.webp";
import WednesburyImageUrl from "../img/outside-wednesbury.webp";


const store = new Vuex.Store({
  state: {
    tiptonBusinessAttribute: "the-island-house-tipton",
    tiptonName: "Tipton",
    tiptonImageUrl: TiptonImageUrl,
    tiptonPhoneNumber: "01215 204348",
    tiptonCoordinates: {"lat": 52.54157,"lng":-2.03937},
    tiptonGoogleMapsUrl: "https://goo.gl/maps/oBQiDuCKVTopLYdAA",
    tiptonAddress: "134 Toll End Rd, Tipton, West Midlands, DY4 0ET",
    tiptonOpeningHours: [
        {
            "day": "Monday",
            "from": "12:00",
            "to":"21:30"
        },
        {
            "day": "Tuesday",
            "from": "12:00",
            "to":"21:30"
        },
        {
            "day": "Wednesday",
            "from": "12:00",
            "to":"21:30"
        },
        {
            "day": "Thursday",
            "from": "12:00",
            "to":"21:30"
        },
        {
            "day": "Friday",
            "from": "12:00",
            "to":"21:30"
        },
        {
            "day": "Saturday",
            "from": "12:00",
            "to":"21:30"
        },
        {
            "day": "Sunday",
            "closed": true,
        }
    ],

    staffordRdBusinessAttribute: "the-island-house-stafford-rd",
    staffordRdName: "Stafford Rd",
    staffordRdGoogleMapsUrl: "https://goo.gl/maps/EbUGJhmRjGnM67sF6",
    staffordRdImageUrl: StaffordRdImageUrl,
    staffordRdPhoneNumber: "01902 902600",
    staffordRdCoordinates: {"lat": 52.60649,"lng":-2.12978},
    staffordRdAddress: "160 Stafford Rd, Wolverhampton, WV10 6JT",
    staffordRdOpeningHours:  [
        {
            "day": "Monday",
            "from": "12:00",
            "to":"21:30"
        },
        {
            "day": "Tuesday",
            "from": "12:00",
            "to":"21:30"
        },
        {
            "day": "Wednesday",
            "from": "12:00",
            "to":"21:30"
        },
        {
            "day": "Thursday",
            "from": "12:00",
            "to":"21:30"
        },
        {
            "day": "Friday",
            "from": "12:00",
            "to":"21:30"
        },
        {
            "day": "Saturday",
            "from": "12:00",
            "to":"21:30"
        },
        {
            "day": "Sunday",
            "closed":true
        }
    ],


    edgbastonBusinessAttribute: "the-island-house-edgbaston",
    edgbastonName: "Edgbaston",
    edgbastonGoogleMapsUrl: "https://goo.gl/maps/MBc4N4WgYrYZ9vF16",
    edgbastonImageUrl: EdgbastonImageUrl,
    edgbastonPhoneNumber: "01214 405452",
    edgbastonCoordinates: {"lat": 52.45722,"lng":-1.90643},
    edgbastonAddress: "7 Hollies Croft, Birmingham, B5 7QN",
    edgbastonOpeningHours: [
        {
            "day": "Monday",
            "from": "11:30",
            "to":"21:30"
        },
        {
            "day": "Tuesday",
            "from": "11:30",
            "to":"21:30"
        },
        {
            "day": "Wednesday",
            "from": "11:30",
            "to":"21:30"
        },
        {
            "day": "Thursday",
            "from": "11:30",
            "to":"21:30"
        },
        {
            "day": "Friday",
            "from": "11:30",
            "to":"22:00"
        },
        {
            "day": "Saturday",
            "from": "12:00",
            "to":"21:30"
        },
        {
            "day": "Sunday",
            "closed":true
        }
    ], 

    newbridgeBusinessAttribute: "the-island-house-newbridge",
    newbridgeName: "Newbridge",
    newbridgeGoogleMapsUrl: "https://goo.gl/maps/2CwHbDVs1i6KUgxGA",
    newbridgeImageUrl: NewbridgeImageUrl,
    newbridgePhoneNumber: "01902 752559",
    newbridgeCoordinates: {"lat": 52.59590,"lng":-2.15917},
    newbridgeAddress: "103-105 Newbridge Cres, Wolverhampton, WV6 0LF",
    newbridgeOpeningHours: [
        {
            "day": "Monday",
            "from": "12:00",
            "to":"21:30"
        },
        {
            "day": "Tuesday",
            "from": "12:00",
            "to":"21:30"
        },
        {
            "day": "Wednesday",
            "from": "12:00",
            "to":"21:30"
        },
        {
            "day": "Thursday",
            "from": "12:00",
            "to":"21:30"
        },
        {
            "day": "Friday",
            "from": "12:00",
            "to":"22:00"
        },
        {
            "day": "Saturday",
            "from": "12:00",
            "to":"22:00"
        },
        {
            "day": "Sunday",
            "from": "15:00",
            "to":"21:00"
        }
    ],  


    
    dudleyBusinessAttribute: "the-island-house-dudley",
    dudleyName: "Dudley",
    dudleyGoogleMapsUrl: "https://goo.gl/maps/EK2WKhJwDBPWh1iW9",
    dudleyImageUrl: DudleyImageUrl,
    dudleyPhoneNumber: "01384 594685",
    dudleyCoordinates: {"lat": 52.52136,"lng":-2.08816},
    dudleyAddress: "137 Priory Road, Dudley, DY1 4EH",
    dudleyOpeningHours: [
        {
            "day": "Monday",
            "from": "12:00",
            "to":"21:00"
        },
        {
            "day": "Tuesday",
            "from": "12:00",
            "to":"21:00"
        },
        {
            "day": "Wednesday",
            "from": "12:00",
            "to":"21:00"
        },
        {
            "day": "Thursday",
            "from": "12:00",
            "to":"21:00"
        },
        {
            "day": "Friday",
            "from": "12:00",
            "to":"21:00"
        },
        {
            "day": "Saturday",
            "from": "12:00",
            "to":"21:00"
        },
        {
            "day": "Sunday",
            "closed":true
        }
    ], 

    eveshamBusinessAttribute: "the-island-house-evesham",
    eveshamName: "Evesham",
    eveshamGoogleMapsUrl: "https://maps.app.goo.gl/ddsDs2dVSzUaNGy1A",
    eveshamImageUrl: EveshamImageUrl,
    eveshamPhoneNumber: "01384 594685",
    eveshamCoordinates: {"lat": 52.09187899401197,"lng":-1.9494407184401172},
    eveshamAddress: "4-5 Merstow Grn, Evesham, WR11 4BB",
    eveshamOpeningHours: [
        {
            "day": "Monday",
            "from": "12:00",
            "to":"21:30"
        },
        {
            "day": "Tuesday",
            "from": "12:00",
            "to":"21:30"
        },
        {
            "day": "Wednesday",
            "from": "12:00",
            "to":"21:30"
        },
        {
            "day": "Thursday",
            "from": "12:00",
            "to":"21:30"
        },
        {
            "day": "Friday",
            "from": "12:00",
            "to":"22:00"
        },
        {
            "day": "Saturday",
            "from": "12:00",
            "to":"22:00"
        },
        {
            "day": "Sunday",
            "from": "12:00",
            "to":"20:30"
        }
    ], 

    dudleyPortBusinessAttribute: "the-island-house-dudley-port",
    dudleyPortName: "Dudley Port",
    dudleyPortGoogleMapsUrl: "https://maps.app.goo.gl/jDoHq6Q8f89p9uwN8",
    dudleyPortImageUrl: DudleyPortImageUrl,
    dudleyPortPhoneNumber: "01384 882016",
    dudleyPortCoordinates: {"lat": 52.522417411772594,"lng":-2.0520942134136084},
    dudleyPortAddress: "265 Dudley Port, Tipton, DY4 7RP",
    dudleyPortOpeningHours: [
        {
            "day": "Monday",
            "from": "11:45",
            "to":"21:00"
        },
        {
            "day": "Tuesday",
            "from": "11:45",
            "to":"21:00"
        },
        {
            "day": "Wednesday",
            "from": "11:45",
            "to":"21:00"
        },
        {
            "day": "Thursday",
            "from": "11:45",
            "to":"21:00"
        },
        {
            "day": "Friday",
            "from": "11:45",
            "to":"21:30"
        },
        {
            "day": "Saturday",
            "from": "11:45",
            "to":"21:30"
        },
        {
            "day": "Sunday",
            closed:true
        }
    ], 
    shardEndBusinessAttribute: "the-island-house-shard-end",
    shardEndName: "Shard End",
    shardEndGoogleMapsUrl: "https://maps.app.goo.gl/3migaMd8QM4e2xwy8",
    shardEndImageUrl: ShardEndImageUrl,
    shardEndPhoneNumber: "0121 730 2379",
    shardEndCoordinates: {"lat": 52.494624072668906,"lng":-1.7777356464638834},
    shardEndAddress: "9 Ownall Road, Birmingham, B34 7AH",
    shardEndOpeningHours: [
        {
            "day": "Monday",
            "from": "12:00",
            "to":"22:00"
        },
        {
            "day": "Tuesday",
            "from": "12:00",
            "to":"22:00"
        },
        {
            "day": "Wednesday",
            "from": "12:00",
            "to":"22:00"
        },
        {
            "day": "Thursday",
            "from": "12:00",
            "to":"22:00"
        },
        {
            "day": "Friday",
            "from": "12:00",
            "to":"22:00"
        },
        {
            "day": "Saturday",
            "from": "12:00",
            "to":"22:00"
        },
        {
            "day": "Sunday",
            closed:true
        }
    ], 
    wednesburyBusinessAttribute: "the-island-house-wednesbury",
    wednesburyName: "Wednesbury",
    wednesburyGoogleMapsUrl: "https://maps.app.goo.gl/ZzQrfUZvApj1dGFg6",
    wednesburyImageUrl: WednesburyImageUrl,
    wednesburyPhoneNumber: "0121 502 3140",
    wednesburyCoordinates: {"lat": 52.55631783677585,"lng":-2.000718198008359},
    wednesburyAddress: "299 Crankhall Lane Wednesbury, WS10 0DX",
    wednesburyOpeningHours: [
        {
            "day": "Monday",
            "from": "12:00",
            "to":"21:00"
        },
        {
            "day": "Tuesday",
            "from": "12:00",
            "to":"21:00"
        },
        {
            "day": "Wednesday",
            "from": "12:00",
            "to":"22:00"
        },
        {
            "day": "Thursday",
            "from": "12:00",
            "to":"21:00"
        },
        {
            "day": "Friday",
            "from": "12:00",
            "to":"21:00"
        },
        {
            "day": "Saturday",
            "from": "12:00",
            "to":"21:00"
        },
        {
            "day": "Sunday",
            closed:true
        }
    ], 
    
  },
});

export default store;